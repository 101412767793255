import React from 'react'
import { Space,Input,Button,Form,message } from 'antd';
import { useNavigate } from 'react-router-dom';
import env from "react-dotenv";
import axios from 'axios';
import './NewPassword.css';

const NewPassword = ({email,otp}) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const SubmitNewPassword = async (newPassword) => { 
        try {
          axios.defaults.withCredentials = true;
          return await axios.post(`${env.REACT_APP_BACK_URL}/set-password`, {newPassword, email,otp});
        } catch (err) {
          return false;
        }
    };

    const onFinish = async (values) => {
     
      if (values.newPassword === values.confirmNewPassword) {
        // Logique de traitement du nouveau mot de passe
        // console.log('Nouveau mot de passe défini avec succès:', values.newPassword);
        const rePassworded = await SubmitNewPassword(values.newPassword);
        if(rePassworded.data.success){
          message.success('Your password has been changed');
          setTimeout(()=>{
            navigate('/login')
          },2000)
        }else{
          message.error('Password can\'t be changed!');
        }
      } else {
        message.error('The passwords are not matching!');
      }
    };

    return (
    <>
    <div className="form-cont" >
        <h3>New Password</h3>
        <Space direction="vertical" size="large">
            <Form
              form={form}
              name="passwordResetForm"
              onFinish={onFinish}
              layout="vertical"
            >
                <Form.Item
                  name="newPassword"
                  label="New password"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter new password!',
                    },
                  ]}
                >
                <Input.Password placeholder="Enter your new password" />
                </Form.Item>
                <Form.Item
                    name="confirmNewPassword"
                    label="Confirmation"
                    dependencies={['newPassword']}
                    rules={[
                      {
                        required: true,
                        message: 'Re-enter your new password',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The passwords are not matching!'));
                        },
                      }),
                    ]}
                >
                <Input.Password placeholder="Confirm your new password" />
                </Form.Item>
                <Form.Item>
                    <p></p>
                  <Button type="primary" htmlType="submit">
                    Validate
                  </Button>
                </Form.Item>
            </Form>  
        </Space>
    </div>
    </>
    )
}

export default NewPassword