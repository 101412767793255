import './Home.css'
import { Footer } from '../../components/Footer/Footer';
import { MainCard } from '../../components/MainCard/MainCard';

const Home = ({loggedStatus, changeLoginStatus,setHasGeneration}) => {
return (
    <>
        <MainCard loggedStatus={loggedStatus} changeLoginStatus={changeLoginStatus} setHasGeneration={setHasGeneration}></MainCard>
        <Footer></Footer>
    </>
)
};

export default Home;