import React from 'react';
import { Alert, Space } from 'antd';
import './Alertbox.css'
const Alertbox = ({type,text}) => (
  <Space
    direction="vertical"
    style={{
      width: '100%',
    }}
  >
    <Alert className="fallbox"
      message={(<>{text.split('@')[0]}<br/>{text.split('@')[1]}</>)}
      type={type}
      showIcon
    />
  </Space>
);
export default Alertbox;