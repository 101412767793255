import React from 'react'
import Navdash from '../Navdash/Navdash'
import MainDomain from '../MainDomain/MainDomain'



const LayoutDomainConnectCon = ({ userInfos, handleToggle }) => {
  return (
    <div className='layout-container'>
        <Navdash userInfos={userInfos} handleToggle={handleToggle}/>
        <MainDomain userInfos={userInfos}/>
    </div>
  )
}

export default LayoutDomainConnectCon