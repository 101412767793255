
import { Space,Input,Button,Form,message } from 'antd';
import React, { useRef } from 'react';
import './inputOtp.css';
import env from "react-dotenv";
import axios from 'axios';


const InputOtp = ({setStep1,setStep2,setStep3,email, setOtp}) => {


    const [form] = Form.useForm()

    const digit2Ref = useRef();
    const digit3Ref = useRef();
    const digit4Ref = useRef();

    const SubmitOtp = async (otp) => { 
        try {
          axios.defaults.withCredentials = true;
          return await axios.post(`${env.REACT_APP_BACK_URL}/check-otp`, {otp,email});
        } catch (err) {
          return false;
        }
    };

    const handleSubmit = () => {
        form.validateFields()
          .then(async values => {
            // Si tous les champs sont validés, on peut procéder
            const myOtp = Object.values(values).join('');
            //console.log('otp reconstitué', myOtp);
            // Si besoin de changer l'étape ici, on peut le faire
            const otpAnswer = await SubmitOtp (myOtp);
            //console.log( 'Reponse verification otp',otpAnswer.data )
            if (otpAnswer.data.success){
                message.success(otpAnswer.data.message)
                setOtp(myOtp);
                setTimeout(()=>{
                     setStep2(false);
                     setStep3(true);
                  },2000)
            }else{
                message.error(otpAnswer.data.message)
                setTimeout(()=>{
                    setStep1(true);
                    setStep2(false);
                 },2000)
            }
          })
          .catch(errorInfo => {
            // Gestion des erreurs de validation ici
            console.log('Validation Failed:', errorInfo);
          });
    }
    const handleInputChange = (nextRef) => {
        if (nextRef.current) {
            nextRef.current.focus();
        }
    };
    return (
      <>
      <Form form={form} layout="vertical" className="form-cont">
        <Space direction="vertical" size="middle">
          <h3>Insert OTP</h3>
          <span className='otp-txt'>Please insert the OTP you received</span>
          <Space direction="horizontal" size="middle">
              <Form.Item name="digit1" rules={[{ required: true, message: ' ' }]}>
                  <Input className='square' maxLength={1} onChange={() => handleInputChange(digit2Ref)} />
              </Form.Item>
              <Form.Item name="digit2" rules={[{ required: true, message: ' ' }]}>
                  <Input className='square' maxLength={1} ref={digit2Ref} onChange={() => handleInputChange(digit3Ref)} />
              </Form.Item>
              <Form.Item name="digit3" rules={[{ required: true, message: ' ' }]}>
                  <Input className='square' maxLength={1} ref={digit3Ref} onChange={() => handleInputChange(digit4Ref)}  />
              </Form.Item>
              <Form.Item name="digit4" rules={[{ required: true, message: ' ' }]}>
                  <Input className='square' maxLength={1} ref={digit4Ref} onChange={() => handleSubmit()}/>
              </Form.Item>
          </Space>
          <Button type="primary" onClick={handleSubmit}>
            Validate
          </Button>
        </Space>
      </Form>
      </>
    )
}


export default InputOtp