import React from 'react'
import { Button, Result, ConfigProvider } from 'antd';
import { useNavigate } from 'react-router-dom';
import './Registred.css'
const Registred = () => {
    let navigate = useNavigate();

    const queryParameters = new URLSearchParams(window.location.search)
    let email;
    queryParameters.get("email") ? email = atob(queryParameters.get("email")) : email = undefined
    const validation = atob(queryParameters.get("validation"))
    //console.log('Parameters',queryParameters.get("email"))

    let resultComponent;

    const handleClick = () => {
        navigate('/login')
    } 

    if (validation === "YES") {
        resultComponent = (
            <Result
                status="success"
                title={(
                <>
                    Congratulations! <br/>Your email address {email} has been approved.
                </>)}
                subTitle="Please log in to access generation features."
            />
        );

    } else if (validation === "ALREADY") {
        resultComponent = (
            <Result
                title={`Your email address "${email}" is already verified.`}
                extra={
                    <Button  className="custom-button" type="primary" key="console" onClick={handleClick}>
                        Back to login
                    </Button>
                }
            />
        );
    } else{
        resultComponent = (
            <Result
                status="warning"
                title={`Your email address "${email}" can't be verified.`}
                subTitle="Please log in to access generation features."
                extra={
                    <Button  className="custom-button" type="primary" key="console"  onClick={handleClick}>
                        Back to login
                    </Button>
                }
            />
        );
    }

    return (
    <ConfigProvider
    theme={{
      components: {
        Button: {
          colorPrimary: `linear-gradient(to right, #F1A300, #CB1000)`,
          colorPrimaryBorder:`linear-gradient(to right, #F1A300, #CB1000)`,
          colorPrimaryHover: `linear-gradient(185deg, #CB1000, #F1A300)`,
          colorPrimaryActive: `linear-gradient(185deg, #CB1000, #F1A300)`,
          lineWidth: 1,
          fontWeightStrong:700
        }
      },
    }}
  >
    <div className='steps-home'>
        <div className='step1'>
            <div className='global-cont'>{resultComponent}
            </div>
        </div>
    </div>
  </ConfigProvider>
    )
}

export default Registred