import React from 'react'
import Navdash from '../Navdash/Navdash'
import MainDash from '../MainDash/MainDash'

const LayoutDashCont = ({ userInfos, handleToggle }) => {
  return (
    <div className='layout-container'>
        <Navdash userInfos={userInfos} handleToggle={handleToggle}/>
        <MainDash userInfos={userInfos}/>
    </div>
  )
}

export default LayoutDashCont