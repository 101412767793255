import React, { useState, useEffect } from 'react';
import Sidedash from '../../components/Sidedash/Sidedash';
import LayoutSupportCont from '../../components/LayoutSupportCont/LayoutSupportCont';
import { useNavigate } from 'react-router-dom';
import './Suport.css';
import env from 'react-dotenv';

const Support = ({ passLoginStatus, loginStatus, generationStatus, handlelogin }) => {
  console.log('SUPPORT => logged', loginStatus);
  console.log('SUPPORT => hasGeneration', generationStatus);

  let navigate = useNavigate();

  // States pour la gestion de la redirection et des informations utilisateur
  const [goToLogin, setGoToLogin] = useState(false);
  const [userInfos, setUserInfos] = useState(false);
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  // useEffect pour récupérer les informations de l'utilisateur
  useEffect(() => {
    const fetchUserInfos = async () => {
      try {
        const response = await fetch(`${env.REACT_APP_BACK_URL}/getinfosuser`, {
          method: 'GET',
          credentials: 'include',
        });

        const data = await response.json();
        if (data.expired) {
          console.log('session expired');
          setGoToLogin(true);
        } else {
          console.log('logged IN', data);
          setUserInfos(data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchUserInfos();
  }, []);

  // useEffect pour gérer la redirection vers la page de connexion si la session a expiré
  useEffect(() => {
    if (goToLogin) {
      handlelogin(false);
      navigate('/login');
    }
  }, [goToLogin, handlelogin, navigate]);

  // Fonction pour activer ou désactiver la barre latérale
  const toggleSidebar = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  return (
    <section className="support">
      {/* Composant Sidedash pour la barre latérale */}
      <Sidedash passLoginStatus={passLoginStatus} isActive={isSidebarActive} handleToggle={toggleSidebar} />

      {/* Affichage de LayoutSupportCont si userInfos est disponible */}
      {userInfos ? (
        <LayoutSupportCont userInfos={userInfos} handleToggle={toggleSidebar} />
      ) : (
        <></>
      )}
    </section>
  );
};

export default Support;
