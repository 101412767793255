import React, { useEffect, useCallback } from 'react';
import { RoadMap } from '../RoadMap/RoadMap';
import './Step2.css';
import LogoUpload from '../LogoUpload/LogoUpload';

export const Step2 = ({ handleClick, step, setInfoLogoWebsite, setColorLogoWebsite, setUrlLogoWebsite, setStepValid, setErrorMsg, urlLogoWebsite, infoLogoWebsite, colorLogoWebsite ,goToStep}) => {

  /**
   * Handles changes to the logo description.
   * This function updates the local storage with the new description 
   * and updates the state with the new description.
   * 
   * @param {string} description - The new logo description.
   */
  const handleInfoLogo = (description) => {
    localStorage.setItem('infoLogoWebsite', description);
    setInfoLogoWebsite(description);
  };

  /**
   * Handles changes to the logo color.
   * This function updates the local storage with the new color 
   * and updates the state with the new color.
   * 
   * @param {string} color - The new logo color.
   */
  const handleColorLogo = (color) => {
    localStorage.setItem('colorLogoWebsite', color);
    setColorLogoWebsite(color);
  };

  /**
   * Handles changes to the logo URL.
   * This function updates the local storage with the new URL
   * and updates the state with the new URL.
   * 
   * @param {string} url - The new logo URL.
   */
  const handleUrlLogo = (url) => {
    localStorage.setItem('urlLogoWebsite', url);
    setUrlLogoWebsite(url);
  };

  /**
   * Validates the current step.
   * This validation function checks if there is a logo URL provided or both logo description and color are provided.
   * If either condition is met, it sets the step as valid and clears any error messages. 
   * Otherwise, it sets the step as invalid and provides an appropriate error message.
   * 
   * @function
   * @name validateStep
   * @returns {void}
   */
  const validateStep = useCallback(() => {
    if (urlLogoWebsite || (infoLogoWebsite && colorLogoWebsite)) {
      setStepValid(true);
      setErrorMsg('');
    } else {
      setStepValid(false);
      setErrorMsg('Please provide a logo URL or fill in the logo description and color.');
    }
  }, [urlLogoWebsite, infoLogoWebsite, colorLogoWebsite, setStepValid, setErrorMsg]);

  /**
   * useEffect hook that validates the current step whenever urlLogoWebsite, 
   * infoLogoWebsite, or colorLogoWebsite changes.
   *
   * This ensures that the step is validated dynamically as the user inputs
   * or changes data. The validateStep function is called as a side effect
   * whenever any of these dependencies updates.
   *
   * @effect
   * @name useEffect
   */
  useEffect(() => {
    validateStep();
  }, [urlLogoWebsite, infoLogoWebsite, colorLogoWebsite, validateStep]);

  return (
    <div className="step2">
      <h1>UPLOAD YOUR LOGO</h1>
      <LogoUpload logoUrl={handleUrlLogo} />
      <h1>OR CREATE ONE</h1>
      <input type="text" placeholder="Logo Description" onChange={(e) => handleInfoLogo(e.target.value)} />
      <input type="text" placeholder="Colour" onChange={(e) => handleColorLogo(e.target.value)} />
      <div>
        <button 
          className="gradient-button"
          onClick={handleClick}
        >
          Submit
        </button>
      </div>
      <RoadMap stepsCompleted={step} goToStep={goToStep} />
    </div>
  );
};
