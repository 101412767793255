import React, { useState } from 'react';
import { Space, ConfigProvider } from 'antd';
import EmailRecovery from '../../components/EmailRecovery/EmailRecovery';
import InputOtp from '../../components/InputOtp/InputOtp';
import NewPassword from '../../components/NewPassword/NewPassword';
import './PassRecovery.css';
import { Footer } from '../../components/Footer/Footer';

const PassRecovery = () => {
  const [email, setEmail] = useState('');
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [otp, setOtp] = useState('');

  return (
      <ConfigProvider
          theme={{
              token: {
                  colorPrimary: 'linear-gradient(91.07deg, #F1A300 19.69%, #CB1000 85.02%)',
                  colorPrimaryHover: 'linear-gradient(91.07deg, #F1A300 19.69%, #CB1000 85.02%)',
                  colorPrimaryActive: 'linear-gradient(91.07deg, #F1A300 19.69%, #CB1000 85.02%)',
              },
              components: {
                  Button: {
                      hover: {
                          background: 'linear-gradient(91.07deg, #F1A300 19.69%, #CB1000 85.02%)',
                      },
                      active: {
                          background: 'linear-gradient(91.07deg, #F1A300 19.69%, #CB1000 85.02%)',
                      },
                  },
              },
          }}
      >
          <div className='recovery-form-cont'>
             <div className='steps-home'>
                 <div className='step1'>
                    <div className="alert-cont">
                    </div>
                        <Space
                            direction="vertical"
                            style={{
                                width: '100%',
                            }}
                        >
                        </Space >
                        {step1 && <EmailRecovery className="block" setStep1={setStep1} setStep2={setStep2} setEmail={setEmail} email={email} />}
                        {step2 && <InputOtp className="block" setStep1={setStep1} setStep2={setStep2} setStep3={setStep3} email={email} setOtp={setOtp} />}
                        {step3 && <NewPassword className="block" setStep1={setStep1} setStep2={setStep2} setStep3={setStep3} email={email} otp={otp} />}
                 </div>
             </div>
              <Footer />
          </div>
      </ConfigProvider>
  );
};


export default PassRecovery