import React from 'react'
import {FacebookShareButton,TwitterShareButton} from "react-share";
import { FacebookIcon, XIcon } from "react-share";
//import env from "react-dotenv";
import './Sharing.css'
const Sharing = ({directory}) => {
  return (
    <div className='sharing-cont'>
        <div className='sharing-btn-cont'>
            <div className='sharing-btn-inner-cont'>
                <FacebookShareButton
                    url={directory ? `https://${directory}.weebseat.com/` : `https://weebseat.com/`}
                    /*url ={directory ? `${env.REACT_APP_BACK_URL}/${directory}/index.html` :`${env.REACT_APP_BACK_URL}` }*/
                    /*url ={directory ? `${env.REACT_APP_GENERATTION}/${directory}/index.html` :`https://weebseat.com/` }*/
                    //url ='https://mac-assistance.fr'
                    quote={"z"}
                    hashtag={"#WeebSeat"}
                    description={"Just tried WeebSeat, the incredible free website builder. Check it out!"}
                >
                    <FacebookIcon  round className='share-icn'/>
                </FacebookShareButton>
                <TwitterShareButton
                    title={"Just tried WeebSeat, the incredible free website builder. Check it out!"}
                    url={directory ? `https://${directory}.weebseat.com/` : `https://weebseat.com/`}
                    /*url  ={directory ? `${env.REACT_APP_BACK_URL}/${directory}/index.html` :`${env.REACT_APP_BACK_URL}` }*/
                    /*url  ={directory ? `${env.REACT_APP_GENERATTION}/${directory}/index.html` :`https://weebseat.com/` }*/
                    //url ='https://mac-assistance.fr'
                    hashtags={["WeebSeat","WebsiteBuilder","FreeWebsite","AiWebDesign","CreateYourSite","NoCodeWebsite","WebDesignTools","OnlinePresence","DigitalToolbox","InnovativeWeb"]}
                >
                    <XIcon round className='share-icn'/>
                </TwitterShareButton>
            </div>
        </div>
    </div>
  )
}

export default Sharing