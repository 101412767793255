import React, { useState } from 'react';
import env from "react-dotenv";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import './LogoUpload.css'

////////////////////////////////////////////
// Verification format & taille de fichier
/////////////////////////////////////////// 
const beforeUpload = (file) => {
  //console.log('info file:',file)
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};
//////////////////////////////////////////////
///////// Composant Upload            ///////
////////////////////////////////////////////
const LogoUpload = ({logoUrl}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();


  
  const handleChange = (info) => {
    
    if(info.fileList.length > 1){
      info.fileList.shift()
    }
    
    //console.log('Info onChange:',info)
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      setLoading(false);
      if(info.file.response){
        if(info.file.response.success){
          setImageUrl(info.file.response.url);
          logoUrl(info.file.response.path)
        }
      }
    }
  };
  

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      className='btn-circle'
      type="button"
    >
      {loading ? <LoadingOutlined style={{ color:'#e16400'}}/> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
         
        }}
      >
        Select file <br/>
        1024 x 1024
      </div>
    </button>
  );
  //Rendu du composant
  return (
    <div className='upload-cont'>
      <div className={`ws-logo-up`}>
        <Upload
          name="image"
          listType="picture-circle"
          className="avatar-uploader"
          showUploadList={false}
          action={env.REACT_APP_BACK_URL+'/uploadlogo'}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="logo"
              style={{
                width: '100%',
                borderRadius: '50%',
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
    </div>
  );
};
export default LogoUpload;