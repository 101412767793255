import React, { useEffect, useState } from 'react';
import {message} from 'antd';
import { useNavigate } from 'react-router-dom';
import env from 'react-dotenv'
import '../PayPalButton/PaypalButton.css';

const PayPalButton = () => {
    const [token, setToken] = useState(null);
    const navigate = useNavigate(); // Initialize the useNavigate hook

    useEffect(() => {
        // Fetch the token from the /usertoken endpoint
        fetch(`${env.REACT_APP_BACK_URL}/usertoken`,{
                method: 'GET',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'}
            })
            .then(response => response.json())
            .then(data => {
                if (data.loggedIn) {
                    console.log('data dispo a passer',data)
                    setToken(data.token);
                } else {
                    navigate('/login'); // Redirect to login if not logged in
                }
            })
            .catch(error => console.error("Error fetching token:", error));
    }, [navigate]);

    useEffect(() => {
        if (token) {
            const script = document.createElement("script");
            script.src = `https://www.paypal.com/sdk/js?client-id=${env.REACT_PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
            script.async = true;
            script.onload = () => {
                if (window.paypal) {
                    window.paypal.Buttons({
                        style: {
                            shape: 'rect',
                            color: 'white',
                            layout: 'vertical',
                            label: 'subscribe',
                            height: 46,
                            width: '50vw'
                        },
                        createSubscription: function (data, actions) {
                            console.log('createSubscription',data)
                            console.log('createSubscription',actions)

                            return actions.subscription.create({
                                plan_id: `${env.REACT_PAYPAL_PLAN_ID}`,
                                custom_id: token // Use the fetched token here
                            });
                        },
                        onApprove: function (data, actions) {
                            console.log('onApprouve',data)
                            console.log('onApprouve',actions)
                            // Afficher un message de succès
                            message.success('Subscription approved successfully!', 2); // Affiche un message de succès pendant 2 secondes
                            
                            // Ajouter la balise de conversion Google Ads
                            if (window.gtag) {
                                window.gtag('event', 'conversion', {
                                    send_to: 'AW-11466370366/6wiHCLbmtaEZEL7ay9sq', 
                                    'value': 1.0, 
                                    'currency': 'THB', // Remplace 'ConversionLabel' par l'étiquette de conversion appropriée
                                    transaction_id: data.subscriptionID // Optionnel : Associe l'ID de la transaction à la conversion
                                });
                            }

                            // Rediriger vers le tableau de bord ou recharger après 2 secondes
                            setTimeout(() => {
                                navigate('/dashboard'); // Redirige vers le tableau de bord
                                // Si vous voulez recharger la page actuelle, utilisez :
                                // window.location.reload();
                            }, 2000); // Rechargement après 2 secondes
                        }
                    }).render('#paypal-button-container');
                } else {
                    console.error("PayPal SDK not loaded correctly.");
                }
            };
            document.body.appendChild(script);
        }
    }, [token,navigate]);

    return (
        <div className="paypal-button-wrapper">
            <div id="paypal-button-container"></div>
        </div>
    );
};

export default PayPalButton;
