import React, { useState } from 'react';
import { Button, Input, Form, ConfigProvider, message } from 'antd';
import './MainSupport.css';
import env from 'react-dotenv';

/**
 * MainSupport component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.userInfos - The user information passed as a prop.
 * @param {string} props.userInfos.username - The username of the user.
 * @param {string} props.userInfos.email - The email address of the user.
 *
 * @returns {JSX.Element} The MainSupport component.
 */
const MainSupport = ({ userInfos }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    /**
     * Handles the submission of the support form.
     *
     * This function sends the form data to the backend server and provides feedback to the user based 
     * on the success or failure of the submission. It shows a loading state while the form submission 
     * is in progress and appropriately handles network and server errors.
     *
     * @param {Object} values - The form values collected from the user.
     * @param {string} values.name - The name of the user.
     * @param {string} values.email - The email address of the user.
     * @param {string} values.subject - The subject of the support request.
     * @param {string} values.message - The message body of the support request.
     *
     * @returns {Promise<void>} A promise that resolves after the form submission process is complete.
     *
     * @async
     */
    const handleSubmit = async (values) => {
        setLoading(true);

        try {
            // Sending form data to the backend
            const response = await fetch(`${env.REACT_APP_BACK_URL}/support`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            const data = await response.json();

            if (response.ok) {
                // Display success message
                message.success('Your request has been successfully sent!');
                form.resetFields(); // Reset form after submission
            } else {
                // Handle server errors
                message.error(data.message || 'An error occurred during submission.');
            }
        } catch (error) {
            // Handle network errors
            message.error('A network error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#E16400',
                        colorPrimaryHover: '#E16400',
                        colorPrimaryActive: '#E16400',
                    },
                    Input: {
                        colorPrimary: '#E16400',
                        colorPrimaryHover: '#E16400',
                        colorPrimaryActive: '#E16400',
                        borderColor: '#E0E4EC',
                        focusBorderColor: '#E16400',
                    },
                },
            }}
        >
            <div className="main">
                <div className="dashboardSupportSecTitle">
                    <h1>Contact Support</h1>
                    <p>If you have any issues or questions, feel free to contact us.</p>
                </div>

                <div className="step7 techsupport">
                    <div className="support-box2">
                        <h1>Submit a Support Request</h1>

                        <Form
                            form={form}
                            onFinish={handleSubmit}
                            initialValues={{
                                name: userInfos?.username || '',
                                email: userInfos?.email || '',
                            }}
                            layout="vertical"
                        >
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please enter your name' }]}
                            >
                                <Input placeholder="Enter your name" />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
                            >
                                <Input placeholder="Enter your email" />
                            </Form.Item>

                            <Form.Item
                                label="Subject"
                                name="subject"
                                rules={[{ required: true, message: 'Please enter the subject' }]}
                            >
                                <Input placeholder="Enter the subject" />
                            </Form.Item>

                            <Form.Item
                                label="Message"
                                name="message"
                                rules={[{ required: true, message: 'Please enter your message' }]}
                            >
                                <Input.TextArea rows={5} placeholder="Enter your message" />
                            </Form.Item>

                            <div className="btn-support-cont">
                                <Button type="primary" htmlType="submit" className="gradient-button" loading={loading}>
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </ConfigProvider>
    );
};

export default MainSupport;
