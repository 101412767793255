import React, { useState, useEffect, useCallback } from 'react';
import { RoadMap } from '../RoadMap/RoadMap';
import penIcon from '../../assets/img/pen.svg';
import './Step4.css';

export const Step4 = ({ 
  handleClick,
  step,
  setNameWebsite,
  setThemeWebsite,
  setInfoLogoWebsite,
  setShopEmail,
  setShopPhone,
  setStepValid,
  setErrorMsg,
  nameWebsite,
  themeWebsite,
  infoLogoWebsite,
  shopEmail,
  shopPhone,
  goToStep 
}) => {
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [isEditable, setIsEditable] = useState({
    name: false,
    theme: false,
    logo: false,
    email: false,
    phone: false,
  });

  const handleEditClick = (field) => {
    setIsEditable((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('*Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\+?\d{2,3}?\d{4,10}$/;
    if (!phoneRegex.test(phone)) {
      setPhoneError('*Invalid phone number format');
    } else {
      setPhoneError('');
    }
  };

  const handleChange = (field, value) => {
    switch (field) {
      case 'name':
        setNameWebsite(value);
        localStorage.setItem('nameWebsite', value);
        break;
      case 'theme':
        setThemeWebsite(value);
        localStorage.setItem('themeWebsite', value);
        break;
      case 'logo':
        setInfoLogoWebsite(value);
        localStorage.setItem('infoLogoWebsite', value);
        break;
      case 'email':
        setShopEmail(value);
        localStorage.setItem('shopEmail', value);
        break;
      case 'phone':
        setShopPhone(value);
        localStorage.setItem('shopPhone', value);
        break;
      default:
        break;
    }
    validateStep();
  };

  const validateStep = useCallback(() => {
    if (nameWebsite && themeWebsite && shopEmail && shopPhone && !emailError && !phoneError) {
      setStepValid(true);
      setErrorMsg('');
    } else {
      setStepValid(false);
      setErrorMsg('Only Logo Field can be ignored, and formats must be valid.');
    }
  }, [nameWebsite, themeWebsite, shopEmail, shopPhone, emailError, phoneError, setStepValid, setErrorMsg]);

  useEffect(() => {
    validateStep();
  }, [validateStep]);

  useEffect(() => {
    setNameWebsite(localStorage.getItem('nameWebsite') || '');
    setThemeWebsite(localStorage.getItem('themeWebsite') || '');
    setInfoLogoWebsite(localStorage.getItem('infoLogoWebsite') || '');
    setShopEmail(localStorage.getItem('shopEmail') || '');
    setShopPhone(localStorage.getItem('shopPhone') || '');
  }, [setNameWebsite, setThemeWebsite, setInfoLogoWebsite, setShopEmail, setShopPhone]);

  return (
    <div className="step4">
      <h1>Contact for your website</h1>
      <div className="label-input">
        <label>Name</label>
        <div>
          <input
            type="text"
            placeholder="Name"
            value={nameWebsite || ''}
            onChange={(e) => handleChange('name', e.target.value)}
            readOnly={!isEditable.name}
            className={isEditable.name ? 'editable' : 'readonly'}
          />
          <div onClick={() => handleEditClick('name')} className={`pen-icon ${isEditable.name ? 'editing' : ''}`}>
            <img src={penIcon} alt="Pen Icon" />
          </div>
        </div>
      </div>
      <div className="label-input">
        <label>Theme</label>
        <div>
          <input
            type="text"
            placeholder="Theme"
            value={themeWebsite || ''}
            onChange={(e) => handleChange('theme', e.target.value)}
            readOnly={!isEditable.theme}
            className={isEditable.theme ? 'editable' : 'readonly'}
          />
          <div onClick={() => handleEditClick('theme')} className={`pen-icon ${isEditable.theme ? 'editing' : ''}`}>
            <img src={penIcon} alt="Pen Icon" />
          </div>
        </div>
      </div>
      <div className="label-input">
        <label>Logo</label>
        <div>
          <input
            type="text"
            placeholder="Logo"
            value={infoLogoWebsite || ''}
            onChange={(e) => handleChange('logo', e.target.value)}
            readOnly={!isEditable.logo}
            className={isEditable.logo ? 'editable' : 'readonly'}
          />
          <div onClick={() => handleEditClick('logo')} className={`pen-icon ${isEditable.logo ? 'editing' : ''}`}>
            <img src={penIcon} alt="Pen Icon" />
          </div>
        </div>
      </div>
      <div className="label-input">
        <label>Email</label>
        <div>
          <input
            type="text"
            placeholder="Email"
            value={shopEmail || ''}
            onChange={(e) => handleChange('email', e.target.value)}
            onBlur={(e) => validateEmail(e.target.value)}
            onFocus={() => setEmailError('')}
            readOnly={!isEditable.email}
            className={isEditable.email ? 'editable' : 'readonly'}
          />
          {/*emailError && <span className="error-message">{emailError}</span>*/}
          <div onClick={() => handleEditClick('email')} className={`pen-icon ${isEditable.email ? 'editing' : ''}`}>
            <img src={penIcon} alt="Pen Icon" />
          </div>
        </div>
      </div>
      <div className="label-input">
        <label>Phone</label>
        <div>
          <input
            type="text"
            placeholder="Phone"
            value={shopPhone || ''}
            onChange={(e) => handleChange('phone', e.target.value)}
            onBlur={(e) => validatePhone(e.target.value)}
            onFocus={() => setPhoneError('')}
            readOnly={!isEditable.phone}
            className={isEditable.phone ? 'editable' : 'readonly'}
          />
          {/*phoneError && <span className="error-message">{phoneError}</span>*/}
          <div onClick={() => handleEditClick('phone')} className={`pen-icon ${isEditable.phone ? 'editing' : ''}`}>
            <img src={penIcon} alt="Pen Icon" />
          </div>
        </div>
      </div>
      <div>
        <button className="gradient-button" onClick={handleClick}>Submit</button>
      </div>
      <RoadMap stepsCompleted={step} goToStep={goToStep}/>
    </div>
  );
};
