import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import {  useState, useEffect } from 'react';
import env from "react-dotenv";
import Navbar from './components/Navbar/Navbar';

import Home from './pages/Home/Home';
import Login from './pages/Login/Login'
import Register from './pages/Register/Register';
import Registred from './pages/Registred/Registred';
import Dashboard from './pages/Dashboard/Dashboard';
import Subscription from './pages/Subscription/Subscription';
import PassRecovery from './pages/PassRecovery/PassRecovery'
import './App.css';
import NotFound from './pages/NotFound/NotFound';
//import ReactGA from 'react-ga';
import Suport from './pages/Suport/Suport';
import DomainConnect from './pages/DomainConnect/DomainConnect';
import Cookies from './components/Cookies/Cookies';

//import axios from 'axios';
// Initialize React Ga with your tracking ID
//ReactGA.initialize('G-JVM1RJ58HN');

function App() {
  /*********************************** */
  const [location, setLocation] = useState(window.location.pathname);
  const [logged, setLogged] = useState(() => !!JSON.parse(localStorage.getItem('auth')) );
  const [hasGeneration,setHasGeneration] = useState(() => !!JSON.parse(localStorage.getItem('generation')))
  
  ///PERSISTANCE DE L ETAT DE LOGIN
// useEffect(() => {
//   localStorage.setItem('auth', JSON.stringify(logged));
// }, [logged]);
  useEffect(() => {
    if (logged) {
      localStorage.setItem('auth', JSON.stringify(logged));  // Sauvegarder seulement si logged est true
    } else {
      localStorage.removeItem('auth');  // Effacer spécifiquement la clé 'auth' si logged est false
    }
  }, [logged]);

  ///PERSISTANCE DE L ETAT DE GENERATION
    useEffect(() => {
      localStorage.setItem('generation', JSON.stringify(hasGeneration));
    }, [hasGeneration]);

  // GESTION DE L ETAT DE LOGGIN USER
  //const handleLogged = (value) => {
  //  setLogged(value);
  //  if (!value) localStorage.clear();
  //};
  const handleLogged = (value) => {
    setLogged(value);
    if (!value) {
      // Effacer uniquement les données liées à l'utilisateur (ici, juste 'auth' pour l'exemple)
      localStorage.removeItem('auth');
    }
  };
   ////////////////SAVOIR SI USER A UNE GENERATION OU PAS/////////////
   const checkHasGeneration = async () => {
    try{
      const reponse =  await fetch(`${env.REACT_APP_BACK_URL}/generation`, 
      {
        method: 'GET',
        credentials: 'include',
      })
      .then( resp  => resp.json())
      .then( datas => datas);
      return reponse
    }catch(err){
      return false
    }
  }
  // A LANCER  AU CHARGEMENT  
  useEffect(() => {
    const checkIfLogged = async () => {
      try {
        const response = await fetch(`${env.REACT_APP_BACK_URL}/session`, {
          method: 'GET', 
          credentials: 'include'
        });
        const myStatus = await response.json();
        handleLogged(myStatus.loggedIn);
      } catch (error) {
        console.error('Erreur lors de la vérification du statut de connexion:', error);
        handleLogged(false);
      }
    };
    checkIfLogged();

    const checkGene = async () =>{
        const infoGenerations = await checkHasGeneration();
        //console.log('infoGenerations',infoGenerations)
          if(infoGenerations.loggedIn){
            setHasGeneration(infoGenerations.generation)
          }
    }
    checkGene()
  }, [logged]);



  //console.log('APP => logged',logged)
  //console.log('APP => hasGeneration',hasGeneration)
  //RENDU VISUEL
  return ( 
    <div className="wrapper">
      <Cookies></Cookies>
      <GoogleOAuthProvider clientId="251031045805-g9nr96khmrailb7pgkfir5dppuet8n81.apps.googleusercontent.com">
        <div className="App">
          {/*
          <Router basename='/weebseat'>
          */}
          <Router>
            <RouteRenderer 
              location={location} 
              setLocation={setLocation} 
              loggedStatus={logged} 
              passLoginStatus={handleLogged} 
              hasGeneration={hasGeneration} 
            />
            <div className="main-cont">
              <Routes>
                <Route path="/" Component={() => {
                                              window.location.href = `./landing/index.html`;
                                              return null;
                                          }} 
                />
                <Route path="/generator" element={<Home loggedStatus = { logged } changeLoginStatus = { handleLogged } setHasGeneration={setHasGeneration}/>} />
                <Route path="/login" element={<Login passLoginStatus = { handleLogged }/>} />
                <Route path="/register" element={<Register />} />
                <Route path="/registred" element={<Registred />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/pass-recovery" element={<PassRecovery />} />
                <Route path="/dashboard" element={<Dashboard passLoginStatus={handleLogged} loginStatus={ logged } generationStatus={hasGeneration} handlelogin={handleLogged}/>} />
                <Route path="/domain" element={<DomainConnect passLoginStatus={handleLogged} loginStatus={ logged } generationStatus={hasGeneration} handlelogin={handleLogged} />} />
                <Route path="/subscription" element={<Subscription passLoginStatus={handleLogged} loginStatus={ logged } generationStatus={hasGeneration} handlelogin={handleLogged}/>} />
                <Route path="/support" element={<Suport passLoginStatus={handleLogged} loginStatus={ logged } generationStatus={hasGeneration} handlelogin={handleLogged}/>} />
                <Route path="/faq" Component={() => {
                                              window.location.href = `./landing/index.html#faq`;
                                              return null;
                                          }} 
                />   
                <Route path="/about-us" Component={() => {
                                              window.location.href = `./landing/about-us.html`;
                                              return null;
                                          }} 
                />   
                <Route path="/gdpr" Component={() => {
                                              window.location.href = `./landing/privacy-policy.html`;
                                              return null;
                                          }} 
                />
                <Route path="/agreement" Component={() => {
                                              window.location.href = `./landing/privacy-policy.html`;
                                              return null;
                                          }} 
                />
              </Routes>
            </div>  
          </Router>
        </div>
      </GoogleOAuthProvider>
    </div>
  );
}

function RouteRenderer({ location, setLocation, loggedStatus, passLoginStatus, hasGeneration }) {
  const loc = useLocation();
  
  useEffect(() => {
    setLocation(loc.pathname);
  }, [loc, setLocation]);

  if (location === "/dashboard" || location === "/subscription" || location === "/support" || location === "/domain") {
    return (
      <></>
    );
  }else if(location === "/" ){
    return (<></>)
  }else{
    return (
      <Navbar 
        loggedStatus={loggedStatus} 
        passLoginStatus={passLoginStatus} 
        hasGeneration={hasGeneration} 
      />
    );
  }
}

export default App;

