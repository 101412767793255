import React from 'react'
import Navdash from '../Navdash/Navdash'
import MainSub from '../MainSub/MainSub'


/**
 * LayoutSubCont is a functional component that renders a layout 
 * containing a navigation dashboard and a main content section.
 * 
 * Props:
 * - userInfos: An object containing user information to be passed to Navdash and MainSub components.
 * - handleToggle: A function to handle toggle actions, passed to the Navdash component.
 * 
 * @param {Object} props - The properties object.
 * @param {Object} props.userInfos - User information.
 * @param {Function} props.handleToggle - Function to handle toggle actions.
 * @returns {JSX.Element} The rendered JSX for LayoutSubCont component.
 */
const LayoutSubCont = ({ userInfos, handleToggle }) => {
  return (
    <div className='layout-container'>
        <Navdash userInfos={userInfos} handleToggle={handleToggle}/>
        <MainSub userInfos={userInfos}/>
    </div>
  )
}

export default LayoutSubCont