import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider } from 'antd';
//import env from 'react-dotenv';
import './Cookies.css'; // Import du fichier CSS

const Cookies = () => {
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('cookieConsent')) {
      setConsentGiven(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setConsentGiven(true);
    // Autres actions après acceptation
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setConsentGiven(true);
    // Autres actions après refus
  };

  if (consentGiven) {
    return null; // Ne pas afficher la bannière si le consentement a déjà été donné
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#ffac33', 
          colorTextBase: '#fff', 
          colorBgContainer: 'rgba(163, 163, 163, 0.7)',
          controlHeightLG: 40, // Hauteur des boutons
          controlPaddingHorizontal: 20, // Padding horizontal des boutons
          controlRadius: 4, // Border radius des boutons
          
        },
        components: {
            Button:{
                fontFamily:'gotham pro',
                fontSize:16,
            }
        }
      }}
    >
      <div className="cookie-banner">
        <p>
          By browsing this website, you are accepting cookies,{' '}
          <a className='link-cookies' href={`./landing/terms-of-use.html`}>
            Terms of Use
          </a>{' '}
          and{' '}
          <a className='link-cookies' href={`./landing/privacy-policy.html`}>
            Privacy policy
          </a>.
        </p>
        <div className="cookie-buttons">
          <Button onClick={handleAccept} className="accept-button">
            Accept
          </Button>
          <Button onClick={handleDecline} className="decline-button">
            Decline
          </Button>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Cookies;
