import React from 'react';
import './Navdash.css';
import burger from '../../assets/img/menu.png';

/**
 * Navdash component represents the header navigation/dashboard area of the application.
 * 
 * Props:
 * - userInfos: An object containing user details such as username.
 * - handleToggle: A function to handle the toggle action, typically used for opening or closing the menu.
 * 
 * This component includes:
 * - A hamburger menu icon that triggers the handleToggle function when clicked.
 * - A placeholder for a search bar.
 * - Header content that includes a notification icon, language selector, and profile information.
 * 
 * @param {object} props The properties passed to the component.
 * @param {object} props.userInfos An object with user information including `username`.
 * @param {function} props.handleToggle A function to handle toggle actions (e.g., menu open/close).
 * @returns {JSX.Element} The Navdash component.
 */
const Navdash = ({ userInfos, handleToggle }) => {
  //console.log('dans navdash', userInfos);

  return (
    <div className="header">
      <img id="menu-open" className="hamburger" alt="burger-menu" src={burger} onClick={handleToggle} />
      <div className="search-bar"></div>
      <div className="header-content">
        <div className="notification-icon"></div>
        <div className="language"></div>
        <div className="profile">
          <div>
            <p className="profile-name">{userInfos.username}</p>
            <p className="profile-desc"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navdash;
