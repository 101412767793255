import React from 'react'
import Footer from '../../components/Footer/Footer'
import './NotFound.css'

const NotFound = () => {

  return (
    <>
      <div className="steps-home ">
      <div className="step1 notfound">
        <p>
        <h1>Error 404</h1>
          Page not found
        </p>
      </div>
      </div>
    <Footer/>
    </>
  )
}

export default NotFound