import React from 'react';
import './SubscriptionHistory.css';

// Fonction pour formater la date en MM/DD/YYYY
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Mois en deux chiffres
    const day = ('0' + date.getDate()).slice(-2); // Jour en deux chiffres
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
};

const SubscriptionHistory = ({ transactions }) => {
    console.log('transactions', transactions);

    // Fonction pour obtenir la couleur de la bordure en fonction du statut et du montant
    const getStatusColor = (transaction) => {
        if (transaction.status === 'pending') {
            return 'orange'; // Orange pour paiements en attente
        }
        if (transaction.status === 'completed') {
            return transaction.amount < 0 ? 'red' : 'green'; // Rouge pour remboursement, vert pour paiements complétés
        }
        if (transaction.status === 'refunded') {
            return 'red'; // Rouge pour paiements remboursés
        }
        return 'gray'; // Couleur par défaut pour statuts inconnus
    };

    return (
        <div className="subscription-history-box">
            <h2>Transaction History</h2>
            <ul className="transaction-list">
                {transactions.length === 0 ? (
                    <p>No transactions found.</p>
                ) : (
                    transactions.map((transaction, index) => (
                        <li
                            key={index}
                            className="transaction-item"
                            style={{ borderLeft: `5px solid ${getStatusColor(transaction)}` }}
                        >
                            <div className="transaction-info">
                                <p><strong>Date:</strong> {formatDate(transaction.payment_time)}</p>
                                <p><strong>Status:</strong> {transaction.status}</p>
                                <p
                                    style={{ color: transaction.amount < 0 || transaction.status === 'refunded' ? 'red' : '#333' }} // Couleur rouge pour remboursement ou remboursé
                                >
                                    <strong>Amount:</strong> {transaction.amount} {transaction.currency_code}
                                </p>
                            </div>
                        </li>
                    ))
                )}
            </ul>
        </div>
    );
};

export default SubscriptionHistory;
