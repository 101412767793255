import React from 'react'
import Navdash from '../Navdash/Navdash'
import MainSupport from '../MainSupport/MainSupport'

/**
 * LayoutSupportCont is a functional component that renders a layout containing 
 * navigation and main support sections.
 * 
 * Props:
 * - userInfos: An object containing user information to be passed down to child components.
 * - handleToggle: A function that handles toggle actions, passed down to the Navdash component.
 * 
 * @param {object} props - The props object containing userInfos and handleToggle.
 * @param {object} props.userInfos - User information to pass to child components.
 * @param {function} props.handleToggle - Toggle handler function for the Navdash component.
 * @return {JSX.Element} A JSX element representing the layout container which includes 
 *                       Navdash and MainSupport components.
 */
const LayoutSupportCont = ({ userInfos, handleToggle }) => {
  return (
    <div className='layout-container'>
        <Navdash userInfos={userInfos} handleToggle={handleToggle}/>
        <MainSupport userInfos={userInfos}/>
    </div>
  )
}

export default LayoutSupportCont