import axios from 'axios';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import env from "react-dotenv";
import ReCAPTCHA from "react-google-recaptcha";
import Alertbox from '../../components/Alertbox/Alertbox';
import {
  Button,
  Form,
  Input,
  Space,
  ConfigProvider,
} from 'antd';
import './Register.css';
import { Footer } from '../../components/Footer/Footer';

const Register = () => {
  let navigate = useNavigate();

  const [form] = Form.useForm();
  const [message, setMessage] = useState('');
  const [visible, setVisible] = useState(true);
  const [loadings, setLoadings] = useState(false);
  const [reCaptchaOk, setReCaptchaOk] = useState(false);
  
  const registerUser = async (credentials) => {
    try {
      return await axios.post(`${env.REACT_APP_BACK_URL}/register`, credentials); 
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const handleAlertClose = async () => {
    setVisible(false);
    setMessage('');
  };

  const onFinish = async ({ email, username, password, recaptchav2 }) => {
    setLoadings(true);

    // Vérification du consentement aux cookies
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (cookieConsent !== 'accepted') {
      setMessage(<Alertbox type="error" text="You must accept cookies,terms and privacy policy to register." />);
      setVisible(true);
      setLoadings(false);
      return; // Empêche la soumission du formulaire
    }


    if (email !== '' && password !== '' && username !== '' && recaptchav2 !== '') {
      const registerAnswer = await registerUser({
        email,
        password,
        username,
        recaptchav2,
      });
      if (registerAnswer.data.success) {
        setMessage(<Alertbox type="success" text={registerAnswer.data.message} />);
        setVisible(true);
        setLoadings(false);
        setTimeout(handleAlertClose, 2000);
        form.resetFields();
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      } else {
        setMessage(<Alertbox type="error" text={registerAnswer.data.message} />);
        setVisible(true);
        setLoadings(false);
        setTimeout(handleAlertClose, 2000);
      }
    }
  };

  const onCaptcha = (value) => {
    value !== null ? setReCaptchaOk(true) : setReCaptchaOk(false);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: `linear-gradient(to right, #F1A300, #CB1000)`,
              colorPrimaryBorder:`linear-gradient(to right, #F1A300, #CB1000)`,
              colorPrimaryHover: `linear-gradient(185deg, #CB1000, #F1A300)`,
              colorPrimaryActive: `linear-gradient(185deg, #CB1000, #F1A300)`,
              lineWidth: 1,
            },
            Checkbox: {
              colorPrimary: `#F1A300`,
              colorPrimaryHover: `#F1A300`,
              colorPrimaryActive: `#CB1000`,
            },
          },
        }}
      >
        <div className="steps-home-register">
          <div className="signupMainContainer">
            <div className="alert-cont">
              <Space
                direction="vertical"
                style={{
                  width: '100%',
                }}
              >
                {visible && message}
              </Space>
            </div>
            <div className="signupFormContainer">
              <div className="signupFormOnboardingTitle">
                <div className="signupFormTitle">
                  <p>
                    Welcome to <span>Weebseat</span>
                  </p>
                </div>
                {/*
                <div className="signupFormPara">
                  <p>Securing the places where people live and thrive.</p>
                </div>
                */}
              </div>
              <div className="signupForm">
                <div className="signupTitle">
                  <h2>SIGN UP</h2>
                </div>
                <Form
                  form={form}
                  name="register"
                  onFinish={onFinish}
                  style={{ width: '100%' }}
                  scrollToFirstError
                >
                  <div className="signupInputContainer">
                    <label>Email:</label>
                    <Form.Item
                      name="email"
                      style={{width:"100%",marginBottom:"10px"}}
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ]}
                    >
                      <div className="signupInputField">
                        <Input placeholder="Enter your email" 
                        variant={false}
                        />
                      </div>
                    </Form.Item>
                  </div>
                  <div className="signupInputContainer">
                    <label>Password:</label>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]}
                      hasFeedback
                      style={{width:"100%",marginBottom:"10px"}}
                    >
                      <div className="signupInputField2">
                        <Input.Password 
                        placeholder="Enter your password" 
                        className="togglePassInput"
                        variant={false}
                        style={{width:"100%"}} 
                        />
                      </div>
                    </Form.Item>
                  </div>
                  <div className="signupInputContainer">
                    <label>Confirm Password:</label>
                    <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    style={{width:"100%",marginBottom:"10px"}}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The passwords do not match!'));
                        },
                      }),
                    ]}
                  >
                    <div className="signupInputField2">
                    <Input.Password 
                    placeholder="Confirm your password" 
                    variant={false}
                    style={{width:"100%"}}
                    />
                    </div>
                    </Form.Item>
                  </div>
                  <div className="signupInputContainer">
                    <label>Username:</label>
                    <Form.Item
                      name="username"
                      style={{width:"100%",marginBottom:"25px"}}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your username!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <div className="signupInputField">
                        <Input placeholder="Enter your username" 
                        variant={false}
                        />
                      </div>
                    </Form.Item>
                  </div>
                  {/*  
                  <Form.Item
                    name="agreement"
                    style={{width:"100%",marginBottom:"10px"}}
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                      },
                    ]}
                  >
                  
                    <Checkbox>
                      I have read the <Link to="/agreement">agreement</Link>
                    </Checkbox>
                  </Form.Item>
                      */}
                  <Form.Item
                  
                    name="recaptchav2"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject(new Error('Should valid reCaptcha')),
                      },
                    ]}
                  >
                    <ReCAPTCHA
                      className="mycaptcha"
                      sitekey="6LdfbnkpAAAAALkzXYdVB9KB03W5MtRl9B6qAbfd"
                      onChange={onCaptcha}
                      value={reCaptchaOk}
                    />
                  </Form.Item>
                  <Form.Item
                  style={{marginBottom:"5px"}}
                  >
                    <Button className="signup" type="primary" htmlType="submit" loading={loadings}>
                      Register
                    </Button>
                  </Form.Item>
                </Form>
                <div className="signupFormButtons">
                  <p>Or</p>
                  <Link className="link-cont" to="/login">
                    <button className="excistingAccount">Already have a profile?</button>
                  </Link>
                  <br/>
                  <br/>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </ConfigProvider>
    </>
  );
};

export default Register;
