import React, { useState, useEffect } from 'react';
import { Button, Radio, ConfigProvider } from 'antd';
import paypal from '../../assets/img/paypal.svg';
import creditCard from '../../assets/img/CreditCard.svg';
import lineImg from '../../assets/img/Line.png';
import './MainSub.css';
import PayPalButton from '../PayPalButton/PayPalButton';
import SubscriptionHistory from '../SubscriptionHistory/SubscriptionHistory';
import env from 'react-dotenv';

/**
 * Returns a formatted date string representing the current date.
 * The format is "DD MMM" where DD is the day of the month,
 * and MMM is the abbreviated month name.
 *
 * @returns {string} The formatted date string.
 */
const getDate = () => {
    const today = new Date().toString().split(' ');
    return `${today[2]} ${today[1]}`;
};


const MainSub = ({userInfos}) => {
    console.log('userinfo mainsub',userInfos)
    const [currentDate] = useState(getDate());

    const [selectedPayment, setSelectedPayment] = useState('paypal');
    const [nameOnCard, setNameOnCard] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expireDate, setExpireDate] = useState('');
    const [cvc, setCvc] = useState('');
    const [orderNotes, setOrderNotes] = useState('');
    const [transactions, setTransactions] = useState([]);  // Transactions de l'abonnement
    const [loading, setLoading] = useState(false);

    /**
     * Event handler for changing the selected payment method.
     * 
     * When a user selects a different payment option, this function updates the
     * state to reflect the selected payment method. It also logs the selected
     * payment method to the console for debugging purposes.
     * 
     * @param {Object} e - The event object from the payment method selection.
     * @param {string} e.target.value - The value of the selected payment method.
     */
    const onPaymentChange = e => {
        setSelectedPayment(e.target.value);
        console.log('Selected Payment:', e.target.value);
    };

   /**
    * useEffect to fetch transactions from the backend when PayPal is selected.
    * This effect runs whenever the selectedPayment or userInfos change.
    * If the selected payment method is PayPal, it triggers an async function
    * to send a POST request to the backend to fetch transactions for the 
    * given user ID. It updates the transactions state and the loading state
    * accordingly based on the response from the backend.
    * 
    * Dependencies:
    * - selectedPayment: Represents the selected payment method.
    * - userInfos: Contains user information including the user's ID.
    * 
    * Flow:
    * 1. Set loading state to true.
    * 2. Send a POST request to the backend with user ID.
    * 3. If the response is not OK, throw an error.
    * 4. If the response is OK, parse the JSON and update the transactions state.
    * 5. Set loading state to false.
    *
    * Error Handling:
    * - Logs any errors encountered during the fetch process.
    * - Ensures loading state is set to false in case of an error.
    */
   useEffect(() => {
    if (selectedPayment === 'paypal') {
        const fetchTransactions = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${env.REACT_APP_BACK_URL}/transactions`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({user_id: userInfos.id}),
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }else{

                    const data = await response.json();
                    setTransactions(data);
                    setLoading(false);
                } 
            } catch (error) {
                console.error('Erreur lors de la récupération des transactions:', error);
                setLoading(false);
            }
        };
        fetchTransactions();
    }
}, [selectedPayment,userInfos]);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: `#E16400`,
                        colorPrimaryHover: `#E16400`,
                        colorPrimaryActive: `#E16400`,
                        lineWidth: 1,
                        defaultBg:`#EDF2F6`,
                        controlHeight:60,
                        borderRadius:9.38,
                        defaultBorderColor:`#E0E4EC`,
                        fontSize:19.26,
                        fontFamily:`gotham pro`,
                        fontWeight:500,

                    },
                    Radio: {
                        colorPrimary: `#E16400`,
                        colorPrimaryHover: `#E16400`,
                        colorPrimaryActive: `#E16400`,
                        lineWidth: 1,
                        borderRadius: 9.38,
                        controlHeight: 60,
                        fontSize: 19.26,
                        fontFamily: `gotham pro`,
                        fontWeight: 500,
                        buttonBg: `#EDF2F6`,
                        buttonCheckedBg: `#E16400`,
                        buttonColor: `rgba(0, 0, 0, 0.88)`,
                        buttonSolidCheckedColor: `#fff`,
                        buttonPaddingInline: 15,
                    },
                },
            }}
        >
            <div className="main">
                <div className="dashboardPaymentSecTitle">
                    <h1>Payment Method</h1>
                    <p>{currentDate}</p>
                </div>
                <div className="step7">
                    <div className="payment-box2">
                        <h1>Payment Option</h1>
                        <div className="payment-options">
                            <Radio.Group className="radio-group" onChange={onPaymentChange} value={selectedPayment}>
                                <div className="payment-option-box">
                                    <img src={paypal} alt="Paypal" />
                                    <label htmlFor="paypal">Paypal</label>
                                    <Radio value="paypal" />
                                </div>
                                <img className="lineImg" src={lineImg} alt="" style={{ height: '90px', width: '1px' }} />
                                <div className="payment-option-box">
                                    <img src={creditCard} alt="Credit Card" />
                                    <label htmlFor="card">Debit/Credit Card</label>
                                    <Radio disabled value="card" />
                                </div>
                            </Radio.Group>
                        </div>

                        {selectedPayment === 'card' && (
                            <>
                                <div className="input-box2">
                                    <label htmlFor="nameOnCard">Name on Card</label>
                                    <input
                                        type="text"
                                        id="nameOnCard"
                                        value={nameOnCard}
                                        onChange={(e) => setNameOnCard(e.target.value)}
                                    />
                                </div>
                                <div className="input-box2">
                                    <label htmlFor="cardNumber">Card Number</label>
                                    <input
                                        type="text"
                                        id="cardNumber"
                                        value={cardNumber}
                                        onChange={(e) => setCardNumber(e.target.value)}
                                    />
                                </div>
                                <div className="horizontal-input-box">
                                    <div className="input-box2">
                                        <label htmlFor="expireDate">Expire Date</label>
                                        <input
                                            type="text"
                                            id="expireDate"
                                            placeholder="MM/YY"
                                            value={expireDate}
                                            onChange={(e) => setExpireDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="input-box2">
                                        <label htmlFor="cvc">CVC</label>
                                        <input
                                            type="text"
                                            id="cvc"
                                            value={cvc}
                                            onChange={(e) => setCvc(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="text-parent">
                                    <h2>Additional Information</h2>
                                    <h2>
                                        Order Notes <span>(Optional)</span>
                                    </h2>
                                </div>
                                <textarea
                                    name="orderNotes"
                                    id="orderNotes"
                                    placeholder="Notes about your package."
                                    value={orderNotes}
                                    onChange={(e) => setOrderNotes(e.target.value)}
                                ></textarea>
                                <div className='btn-pay-cont'>
                                    <Button>Submit</Button>
                                </div>
                            </>
                        )}

                        {selectedPayment === 'paypal' && (
                            <div className='BP-cont'>
                                <h2>Basic Plan</h2>
                                <PayPalButton />

                            </div>
                        )}
                    <>
                        {loading ? (
                            <p>Loading transactions...</p>
                        ) : (
                            <SubscriptionHistory transactions={transactions} />
                        )}
                    </>
                    </div>
                </div>
            </div>
        </ConfigProvider>
    );
};

export default MainSub;
